import { Link, useParams, useNavigate, json } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";
import { useState, useEffect } from "react";
import Titlebar from "../components/Titlebar";

export default function DetailCourse() {
  const param = useParams();
  const courseID = param.id;
  const navigate = useNavigate();
  //! POTENSI
  const [course, setCourse] = useState([]);
  const [thumbnail, setThumbnail] = useState("");
  const base_API = process.env.REACT_APP_API_BASE_URL;
  const loadKursus = async () => {
    try {
      const url = `${base_API}/users_courses/detail/${courseID}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setCourse(data.data);
      setThumbnail(base_API + "/" + data.data.logo);
    } catch (error) {
      console.log(error);
    }
  };

  //!MATERI
  const [materi, setMateri] = useState([]);
  const loadMateri = async () => {
    try {
      const url = `${base_API}/users_lesson/list/${courseID}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setMateri(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const CardMateri = () => {
    if (materi.length > 0) {
      return (
        <>
          {materi.map((item, index) => {
            return (
              <Link key={index} to={`/detailmateri/${item.id}`}>
                <div className=" bg-white dark:text-black rounded p-4 flex mb-2">
                  <div className="w-1/12">
                    <img src="/images/Love Book.svg" />
                  </div>
                  <div className="w-11/12">{item.title}</div>
                </div>
              </Link>
            );
          })}
        </>
      );
    } else {
      return <div>Belum ada materi</div>;
    }
  };

  //!KELUAR COURSE
  const keluarCourse = async () => {
    try {
      const response = await fetch(
        `${base_API}/users_courses/quit_course/${courseID}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
      } else if (response.ok) {
        Toast("success", data.message);
        navigate("/home");
      } else {
        Toast("error", data.message);
      }
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };

  useEffect(() => {
    loadKursus();
    loadMateri();
  }, []);
  return (
    <main className=" min-h-screen flex-col items-center justify-between dark:text-white bg-gray-100 dark:bg-gray-800">
      <Titlebar menu="Kursus" />
      <div className="gradient-red-noround pt-16 mx-2 rounded-lg flex">
        <div className="w-4/12 p-3">
          <div className="bg-white p-3 rounded-lg">
            <img className="rounded-lg" src={thumbnail} />
          </div>
        </div>
        <div className="w-8/12 text-white font-semibold text-lg items-center flex">
          {course.title}
        </div>
      </div>
      <div className="p-3">
        <div className="mt-5 font-semibold text-lg">Deskripsi Kursus</div>
        <div
          className=" text-gray-600 dark:text-white"
          dangerouslySetInnerHTML={{ __html: course.desc }}
        ></div>
        <button
          onClick={() => navigate("/detailtugas/" + courseID)}
          className=" p-2 border rounded btn-orange mt-2"
        >
          <div className="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-file-earmark-arrow-up-fill"
              viewBox="0 0 16 16"
            >
              <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707L6.354 9.854z" />
            </svg>
            Upload Hasil Tugas
          </div>
        </button>
        <div className="mt-5 font-semibold text-lg mb-2">Materi Kursus</div>
        <CardMateri />
        <button
          onClick={keluarCourse}
          className="w-full mt-20 p-2 border  border-red-500 text-red-500 rounded-md flex justify-center items-center align-middle"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-box-arrow-left"
            viewBox="0 0 16 16"
          >
            <path d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z" />
            <path d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
          </svg>
          <span className=" ml-2"> Keluar dari kelas</span>
        </button>
      </div>
      <Nav menu="home" />
    </main>
  );
}
