import { Link, useParams, useNavigate, json } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";
import { useState, useEffect, useRef } from "react";
import Titlebar from "../components/Titlebar";
import Loader from "../components/Loader";

export default function DetailTugas() {
  const param = useParams();
  const courseID = param.id;
  const navigate = useNavigate();
  const base_API = process.env.REACT_APP_API_BASE_URL;

  //!HASIL
  const [hasil, setHasil] = useState(null);
  const loadHasil = async () => {
    try {
      setHasil(null);
      const url = `${base_API}/users_lesson/user_answer/${courseID}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setHasil(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const TrHasil = () => {
    if (hasil) {
      if (hasil.length > 0) {
        return (
          <>
            {hasil.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <button
                      className="w-full"
                      onClick={() => {
                        handleDownloadClick(item.attachment);
                      }}
                    >
                      <div className=" bg-blue-100 p-2 rounded font-semibold flex">
                        <div className="w-1/12 flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-download"
                            viewBox="0 0 16 16"
                          >
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                          </svg>
                        </div>
                        <div className="w-11/12 text-left">{item.desc}</div>
                      </div>
                    </button>
                  </td>
                  <td className="text-center">
                    <button
                      className=" text-red-500 "
                      onClick={() => {
                        deleteHasil(item.id);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                      </svg>
                    </button>
                  </td>
                </tr>
              );
            })}
          </>
        );
      } else {
        return (
          <>
            <tr>
              <td>Anda belum mengupload hasil tugas</td>
            </tr>
          </>
        );
      }
    } else {
      return (
        <tr>
          <td colSpan={10}>
            <Loader />
          </td>
        </tr>
      );
    }
  };

  const handleDownloadClick = async (filePath) => {
    try {
      console.log(filePath);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/" + filePath
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const filename = filePath.split("/").pop();
      a.href = url;
      a.download = filename; // Set the desired filename
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const deleteHasil = async (answerID) => {
    try {
      setHasil(null);
      const response = await fetch(
        `${base_API}/users_lesson/delete_answer/${answerID}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
      } else if (response.ok) {
        Toast("success", data.message);
      } else {
        Toast("error", data.message);
      }
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
    loadHasil();
  };

  //! UPLOAD HASIL
  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const fileInputRef = useRef(null);

  const addHasil = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    if (file) {
      formData.append("file", file);
    }
    formData.append("course_id", courseID);

    try {
      setHasil(null);
      const response = await fetch(`${base_API}/users_lesson/add_answer`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });
      const data = await response.json();
      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
      } else if (response.ok) {
        Toast("success", data.message);
        setDesc("");
        fileInputRef.current.value = "";
      } else {
        Toast("error", data.message);
      }
      loadHasil();
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };

  useEffect(() => {
    loadHasil();
  }, []);
  return (
    <main className=" min-h-screen flex-col items-center justify-between bg-white dark:bg-gray-800">
      <Titlebar menu="Tugas" />
      <div className="p-3 bg-white pt-16">
        <div className="mt-5 font-semibold mb-2 text-2xl">
          Dokumen Hasil Tugas
        </div>
        <table className="w-full mt-3">
          <tbody>
            <TrHasil />
          </tbody>
        </table>

        <div className="mt-5 font-semibold mb-2 text-2xl border-t pt-5">
          Upload Hasil Tugas
        </div>
        <form onSubmit={addHasil}>
          <div className="mt-3">
            <label className=" text-sm font-semibold dark:text-white">
              Dokumen Hasil Tugas
            </label>
            <input
              className="block w-full  p-2 border rounded "
              type="file"
              ref={fileInputRef}
              onChange={(e) => {
                const file = e.target.files[0]; // Get the selected file
                setFile(file);
              }}
            />
          </div>
          <div className="mt-3">
            <label className=" text-sm font-semibold dark:text-white w-full">
              Deskrispi
            </label>
            <textarea
              name="desc"
              className="w-full border rounded p-2"
              rows={5}
              value={desc}
              onChange={(e) => {
                setDesc(e.target.value);
              }}
            ></textarea>
          </div>
          <button type="submit" className=" p-2 border rounded btn-orange">
            <div className="flex justify-center">Tambah Hasil Tugas</div>
          </button>
        </form>
      </div>

      <Nav menu="home" />
    </main>
  );
}
