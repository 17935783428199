import { Link, redirect, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { stateFromHTML } from "draft-js-import-html";
import Loader from "../web/components/Loader";

export default function ListSiswaCourse() {
  const param = useParams();
  const courseID = param.id;

  //! LIST SISWA
  const [listSiswa, setListSiswa] = useState(null);
  const loadSiswa = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/guest/list_siswa_by_guest/${courseID}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setListSiswa(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const CardSiswa = () => {
    if (listSiswa) {
      if (listSiswa.length > 0) {
        return (
          <>
            {listSiswa.map((item, key) => (
              <Link
                key={key}
                to={`/hasil-tugas-siswa/${item.id}/${courseID}`}
                className=" w-full md:w-3/12 lg:w-2/12"
              >
                <div className="border m-2 rounded bg-white text-center hover:bg-red-500 hover:text-white">
                  <div
                    className=" bg-cover bg-center  items-end rounded-full w-0 m-auto mt-2"
                    style={{
                      minWidth: 70,
                      minHeight: 70,
                      backgroundImage: `url('${
                        process.env.REACT_APP_API_BASE_URL +
                        "/" +
                        item.avatar.replace(/\\/g, "/").replace(/\s+/g, "")
                      }')`,
                    }}
                  ></div>
                  <div className="p-3 font-semibold">{item.fullname}</div>
                </div>
              </Link>
            ))}
          </>
        );
      } else {
        return (
          <>
            <div className=" w-full lg:w-7/12 m-auto">
              <p className=" text-center font-semibold text-xl">Data Kosong</p>
              <img src="/images/Working from anywhere-pana.svg" />
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <Loader />
        </>
      );
    }
  };

  useEffect(() => {
    loadSiswa();
  }, []);

  return (
    <>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap"
        rel="stylesheet"
      />
      <link href="landing/css/fontawesome-all.css" rel="stylesheet" />
      <link
        href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css"
        rel="stylesheet"
      />
      <link href="landing/css/swiper.css" rel="stylesheet" />
      <link href="landing/css/magnific-popup.css" rel="stylesheet" />
      <link href="landing/css/styles.css" rel="stylesheet" />

      <div className="p-5 bg-red-500 flex">
        <div className="w-4/12">
          <Link
            to="/"
            className=" inline-flex items-center justify-between px-4 py-2 text-xs font-medium leading-5 text-white transition-colors duration-150 bg-red-800 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
          >
            <svg
              className="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <span>Kembali</span>
          </Link>
        </div>
        <div className="w-4/12 text-center text-white font-semibold text-lg">
          Daftar Peserta
        </div>
      </div>
      <div className="p-5 flex ">
        <CardSiswa />
      </div>

      <script src="landing/js/jquery.min.js"></script>
      <script src="landing/js/jquery.easing.min.js"></script>
      <script src="landing/js/swiper.min.js"></script>
      <script src="landing/js/jquery.magnific-popup.js"></script>
      <script src="landing/js/scripts.js"></script>
    </>
  );
}
