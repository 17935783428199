import { Link, useParams, useNavigate, json } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";
import { useState, useEffect } from "react";
import Titlebar from "../components/Titlebar";

export default function DetailExplore() {
  const param = useParams();
  const courseID = param.id;
  const navigate = useNavigate();
  //! POTENSI
  const [course, setCourse] = useState([]);
  const [thumbnail, setThumbnail] = useState("");
  const base_API = process.env.REACT_APP_API_BASE_URL;
  const loadKursus = async () => {
    try {
      const url = `${base_API}/users_courses/detail/${courseID}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setCourse(data.data);
      setThumbnail(base_API + "/" + data.data.logo);
    } catch (error) {
      console.log(error);
    }
  };

  //!MATERI
  const [materi, setMateri] = useState([]);
  const loadMateri = async () => {
    try {
      const url = `${base_API}/users_lesson/list/${courseID}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setMateri(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const CardMateri = () => {
    if (materi.length > 0) {
      return (
        <>
          {materi.map((item, index) => {
            return (
              <div
                className=" bg-white dark:text-black rounded p-2 flex mb-1"
                key={index}
              >
                <div className="w-1/12">
                  <img src="/images/Love Book.svg" />
                </div>
                <div className="w-11/12">{item.title}</div>
              </div>
            );
          })}
        </>
      );
    } else {
      return <div>Belum ada materi</div>;
    }
  };

  //!AMBIL COURSE
  const ambilCourse = async () => {
    try {
      let jsonData = {
        course_id: parseInt(courseID),
      };
      const response = await fetch(`${base_API}/users_courses/take_course`, {
        method: "POST",
        body: JSON.stringify(jsonData),
        credentials: "include",
      });
      const data = await response.json();
      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
      } else if (response.ok) {
        Toast("success", data.message);
        navigate("/home");
      } else {
        Toast("error", data.message);
      }
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };

  useEffect(() => {
    loadKursus();
    loadMateri();
  }, []);
  return (
    <main className=" min-h-screen flex-col items-center justify-between dark:text-white bg-gray-100 dark:bg-gray-800">
      <Titlebar menu="Lihat Kursus" />
      <div className="gradient-red-noround mt-3 pt-16 mx-2 rounded-lg flex">
        <div className="w-4/12 p-3">
          <div className="bg-white p-3 rounded-lg">
            <img className="rounded-lg" src={thumbnail} />
          </div>
        </div>
        <div className="w-8/12 text-white font-semibold text-lg items-center flex">
          {course.title}
        </div>
      </div>
      <div className="p-3">
        <button
          onClick={ambilCourse}
          className="block w-full  p-2 border rounded btn-orange"
        >
          Ambil Kursus
        </button>
        <div className="mt-5 font-semibold text-lg">Deskripsi Kursus</div>
        <div
          className=" text-gray-600 dark:text-white"
          dangerouslySetInnerHTML={{ __html: course.desc }}
        ></div>
        <div className="mt-5 font-semibold text-lg mb-2">Materi Kursus</div>
        <CardMateri />
      </div>

      <Nav menu="explore" />
    </main>
  );
}
