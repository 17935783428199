import { Link, useNavigate } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";
import { useState, useEffect } from "react";
import Loader from "../components/Loader";

export default function Home() {
  const navigate = useNavigate();

  //! USER
  const [fullname, setFullname] = useState("");
  const loadData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/user/getself_user`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();

      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
        return false;
      }

      setFullname(data.data.fullname);
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };

  //! COURSE COUNT
  const [totalCourse, setTotalCourse] = useState(0);
  const loadTotalCourse = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/users_courses/course_count`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();

      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
        return false;
      }
      setTotalCourse(data.data);
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };

  //! COURSE TERAMBIL
  const [courses, setCourses] = useState(null);
  const loadCourse = async () => {
    try {
      setCourses(null);
      const url = `${process.env.REACT_APP_API_BASE_URL}/users_courses/course_taken`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
        return false;
      }
      setCourses(data.data);
      console.log(courses);
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };
  const CourseComponent = () => {
    if (courses) {
      if (courses.length > 0) {
        return (
          <>
            {courses.map((item, index) => {
              return (
                <Link key={index} to={`/detailcourse/${item.id}`}>
                  <div className="flex bg-white rounded-xl mt-3 dark:bg-gray-800 p-4">
                    <div className="w-3/12 flex items-center">
                      <img
                        className="w-full rounded-lg"
                        src={
                          process.env.REACT_APP_API_BASE_URL + "/" + item.logo
                        }
                      />
                    </div>
                    <div className="w-9/12 p-3 flex items-center">
                      {/* <span className="text-xs text-gray-400">{item.title}</span> */}
                      <span className=" font-semibold">{item.title} </span>
                      {/* <div className="text-xs text-right mt-3 hidden">
                  Target tercapai
                </div> */}
                      {/* <div
                  id="progress-bar"
                  className="w-full h-4 bg-gray-300 rounded-full hidden"
                >
                  <div
                    id="progress"
                    className="h-full text-center text-white bg-red-500 rounded-full text-xs"
                    style={{
                      width: 20 + "%",
                    }}
                  >
                    {20}%
                  </div>
                </div> */}
                    </div>
                  </div>
                </Link>
              );
            })}
          </>
        );
      } else {
        return (
          <>
            <div className=" w-full lg:w-7/12 m-auto bg-white p-4">
              <p className=" text-center font-semibold text-base">
                Belum ada kursus diambil
              </p>

              <img src="/images/Working from anywhere-pana.svg" />
              <Link
                to={`/explore`}
                className="block w-full  p-2 border rounded btn-orange"
              >
                Cari Kursus
              </Link>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <Loader />
        </>
      );
    }
  };
  useEffect(() => {
    loadData();
    loadCourse();
    loadTotalCourse();
  }, []);
  return (
    <main className=" min-h-screen flex-col items-center justify-between bg-gray-100 dark:bg-gray-800 dark:text-white">
      <div className="text-right flex justify-end p-3 bg-white dark:bg-gray-800">
        <img
          className=" text-center"
          src="/logo.png"
          width={51}
          height={19}
          alt="Logo"
        />
      </div>
      <div className=" container px-3 pb-5 rounded-b-3xl bg-white dark:bg-gray-800">
        <div className="text-xl font-semibold">Halo, {fullname}</div>
        <div className=" text-gray-400 text-base">
          Mau Belajar Apa Hari Ini?
        </div>
        <div className=" flex mt-4 ">
          <div className=" bg-red-100 w-5/12 h-28 rounded-lg text-red-700 flex ">
            <div className=" w-1/2">
              <div className=" absolute -mt-4 -ml-3">
                <img src="/images/Bookmark.png" width={96} height={96} alt="" />
              </div>
            </div>
            <div className="items-center">
              <div className=" text-red-500 font-bold text-4xl mt-5 text-center">
                {totalCourse}
              </div>
              <div className=" text-red-500 text-center">Kursus</div>
            </div>
          </div>
          <div className=" w-6/12 ml-2 card-gradient-red flex p-3 items-center">
            <Link to={`/explore`} className="flex">
              <div
                className="  text-white font-semibold text-xl 
            "
              >
                Temukan Kursus
              </div>
              <div className=" w-auto">
                <img
                  src="/images/Literature.svg"
                  width={100}
                  height={100}
                  alt=""
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="px-3 mt-3 font-semibold text-base">Kursus Saya</div>
      <div className="px-3 mt-3">
        <CourseComponent />
      </div>
      <Nav menu="home" />
    </main>
  );
}
