import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import WebToast from "../components/WebToast";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { Modal } from "react-overlays";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import Loader from "../components/Loader";

function DetailKursus() {
  const navigate = useNavigate();
  const param = useParams();

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDeleteSiswa, setModalDeleteSiswa] = useState(false);
  const [modalFile, setModalFile] = useState(false);
  const [modalTugas, setModalTugas] = useState(false);
  const handleClose = () => setModalAdd(false);
  const handleEditClose = () => setModalEdit(false);
  const handleDeleteClose = () => setModalDelete(false);
  const handleDeleteSiswaClose = () => setModalDeleteSiswa(false);
  const handleFileClose = () => setModalFile(false);
  const handleTugasClose = () => setModalFile(false);
  const renderBackdrop = (props) => <div className="backdrop" {...props} />;

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  //! DATA STATE
  const [materi, setMateri] = useState(null);
  const [file, setFile] = useState("");
  const [fileTugas, setFileTugas] = useState("");
  const [descTugas, setDescTugas] = useState("");
  const [tugas, setTugas] = useState(null);
  const [title, setTitle] = useState("");
  const [materiID, setMateriID] = useState("");
  const [course, setCourse] = useState([]);
  const [logo, setLogo] = useState("");
  const [dokumen, setDokumen] = useState(null);
  const [siswa, setSiswa] = useState(null);
  const [userCourseID, setuserCourseID] = useState([]);

  const loadCourse = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/course/get_detail_course/${param.id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        setCourse(data.data);
        setLogo(process.env.REACT_APP_API_BASE_URL + "/" + data.data.logo);
      } else {
        WebToast("error", data.message);
      }
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const loadSiswa = async () => {
    try {
      setSiswa(null);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/course/list_siswa/${param.id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        setSiswa(data.data);
      }
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };

  const loadMateri = async () => {
    try {
      setMateri(null);
      const url = `${process.env.REACT_APP_API_BASE_URL}/lesson/get_lesson_by_course/${param.id}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setMateri(data.data);
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
        return false;
      }
    } catch (error) {
      WebToast("error", "Sistem Error");
    }
  };
  const loadFile = async (lesson_id) => {
    try {
      setDokumen(null);
      const url = `${process.env.REACT_APP_API_BASE_URL}/lesson/get_document/${lesson_id}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setDokumen(data.data);
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
        return false;
      }
    } catch (error) {
      WebToast("error", "Sistem Error");
    }
  };
  const loadTugas = async (user_id) => {
    try {
      setTugas(null);
      const url = `${process.env.REACT_APP_API_BASE_URL}/lesson/get_answer/${user_id}/${param.id}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setTugas(data.data);
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
        return false;
      }
    } catch (error) {
      WebToast("error", "Sistem Error");
    }
  };

  useEffect(() => {
    loadCourse();
    loadMateri();
    loadSiswa();
  }, []);

  const handlePost = async (event) => {
    try {
      event.preventDefault();
      setMateri(null);
      const form = event.target;
      const formData = new FormData(form);
      // get value
      const contentState = editorState.getCurrentContent();
      const desc = stateToHTML(contentState);
      formData.append("content", desc);
      formData.append("course_id", param.id);
      formData.append("type_lesson", 0);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/lesson/add_lesson`,
        {
          method: "POST",
          body: formData,
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        WebToast("success", data.message);
        setModalAdd(false);
        loadMateri();
      } else {
        WebToast("error", data.message);
      }
    } catch (error) {
      WebToast("error", "Sistem Error");
    }
  };
  const handlePut = async (event) => {
    try {
      event.preventDefault();
      setMateri(null);
      const form = event.target;
      const formData = new FormData(form);
      // get value
      const contentState = editorState.getCurrentContent();
      const desc = stateToHTML(contentState);
      formData.append("content", desc);
      formData.append("type", 0);

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/lesson/update_lesson/${materiID}`,
        {
          method: "PUT",
          body: formData,
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        WebToast("success", data.message);
        setModalEdit(false);
      } else {
        WebToast("error", data.message);
      }
      loadMateri();
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const handleDelete = async (event) => {
    try {
      setMateri(null);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/lesson/delete_lesson/${materiID}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        WebToast("success", data.message);
        setModalDelete(false);
      } else {
        WebToast("error", data.message);
      }
      loadMateri();
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const handleDeleteSiswa = async (event) => {
    try {
      setSiswa(null);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/course/remove_siswa_from_course/${userCourseID}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        WebToast("success", data.message);
        setModalDeleteSiswa(false);
      } else {
        WebToast("error", data.message);
      }
      loadSiswa();
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const handleUpload = async (event) => {
    try {
      setDokumen(null);
      event.preventDefault();
      const form = event.target;
      const formData = new FormData(form);
      formData.append("lesson_id", materiID);

      if (file) {
        formData.append("file", file);
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/lesson/add_document`,
        {
          method: "POST",
          body: formData,
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        WebToast("success", data.message);
        setFile(null);
      } else {
        WebToast("error", data.message);
      }
      loadFile(materiID);
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const handleUploadTugas = async (event) => {
    try {
      setTugas(null);
      event.preventDefault();
      const form = event.target;
      const formData = new FormData(form);
      formData.append("user_id", userCourseID);
      formData.append("course_id", param.id);

      if (fileTugas) {
        formData.append("file", fileTugas);
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/lesson/teacher_add_answer`,
        {
          method: "POST",
          body: formData,
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        WebToast("success", data.message);
        setFile(null);
      } else {
        WebToast("error", data.message);
      }
      loadTugas(userCourseID);
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const handleDownloadClick = async (filePath) => {
    try {
      console.log(filePath);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/" + filePath
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const filename = filePath.split("/").pop();
      a.href = url;
      a.download = filename; // Set the desired filename
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };
  const handleFileDelete = async (documentID) => {
    try {
      setDokumen(null);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/lesson/delete_document/${documentID}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        WebToast("success", data.message);
      } else {
        WebToast("error", data.message);
      }
      loadFile(materiID);
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const handleTugasDelete = async (tugasID) => {
    try {
      setTugas(null);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/lesson/teacher_delete_answer/${tugasID}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        WebToast("success", data.message);
      } else {
        WebToast("error", data.message);
      }
      loadTugas(userCourseID);
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const TableMateri = (props) => {
    if (materi) {
      if (materi.length > 0) {
        return (
          <>
            {materi.map((item, index) => (
              <tr className="text-gray-700 dark:text-gray-400" key={index}>
                <td className="px-4 py-3 text-sm">{index + 1}</td>
                <td className="px-4 py-3 text-sm">{item.title}</td>
                <td>
                  <button
                    className="flex items-center justify-between px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
                    onClick={() => {
                      setModalFile(true);
                      setMateriID(item.id);
                      loadFile(item.id);
                      setFile(null);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-paperclip"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                    </svg>
                    <span className="text-xs">Lampiran</span>
                  </button>
                </td>
                <td className="px-4 py-3 text-sm">
                  {" "}
                  <div className="flex items-center space-x-4 text-sm">
                    <button
                      onClick={() => {
                        setModalEdit(true);
                        setTitle(item.title);
                        // Convert HTML to ContentState
                        const blocksFromHTML = convertFromHTML(item.content);
                        const contentState = ContentState.createFromBlockArray(
                          blocksFromHTML.contentBlocks,
                          blocksFromHTML.entityMap
                        );
                        setEditorState(
                          EditorState.createWithContent(contentState)
                        );
                        setMateriID(item.id);
                      }}
                      className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-black-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray hover:text-red-500"
                      aria-label="Edit"
                    >
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        setMateriID(item.id);
                        setModalDelete(true);
                      }}
                      className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-black-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray hover:text-red-500"
                      aria-label="View"
                    >
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </>
        );
      } else {
        return (
          <tr>
            <td colSpan={10} className="text-center">
              Tidak ada data
            </td>
          </tr>
        );
      }
    } else {
      return (
        <tr>
          <td colSpan={5}>
            <Loader />
          </td>
        </tr>
      );
    }
  };
  const TableSiswa = (props) => {
    if (siswa) {
      if (siswa.length > 0) {
        return (
          <>
            {siswa.map((item, index) => (
              <tr className="text-gray-700 dark:text-gray-400" key={index}>
                <td className="px-4 py-3 text-sm">{index + 1}</td>
                <td>
                  {" "}
                  <img
                    alt=""
                    style={{ width: "50px" }}
                    src={`${process.env.REACT_APP_API_BASE_URL}/${item.avatar}`}
                  ></img>
                </td>
                <td className="px-4 py-3 text-sm">{item.fullname}</td>
                <td>{item.email}</td>
                <td>
                  <button
                    className="flex items-center justify-between px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
                    onClick={() => {
                      setModalTugas(true);
                      setuserCourseID(item.id);
                      loadTugas(item.id);
                      setFileTugas(null);
                      setDescTugas("");
                    }}
                    style={{ width: 150 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-paperclip"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                    </svg>
                    <span className="text-xs">Lihat Hasil Tugas</span>
                  </button>
                </td>
                <td>
                  {" "}
                  <button
                    title="Keluarkan dari kelas"
                    onClick={() => {
                      setModalDeleteSiswa(true);
                      setuserCourseID(item.id);
                    }}
                    className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-black-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray hover:text-red-500"
                    aria-label="View"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-dash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM11 12h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1Zm0-7a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                      <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </>
        );
      } else {
        return (
          <tr>
            <td colSpan={10} className="text-center">
              Tidak ada data
            </td>
          </tr>
        );
      }
    } else {
      return (
        <tr>
          <td colSpan={6}>
            <Loader />
          </td>
        </tr>
      );
    }
  };
  const TableFile = () => {
    if (dokumen) {
      if (dokumen.length > 0) {
        return (
          <>
            <thead>
              <tr className="border">
                <th className="px-4 py-3 text-left">No.</th>
                <th className="px-4 py-3 text-left">Dokumen</th>
                <th className="px-4 py-3 text-left">Hapus</th>
              </tr>
            </thead>
            <tbody>
              {dokumen.map((item, index) => (
                <tr key={index}>
                  <td className="px-4 py-3 text-sm">{index + 1}.</td>
                  <td className="px-4 py-3">
                    <button
                      onClick={() => {
                        handleDownloadClick(item.filename);
                      }}
                      type="button"
                      className=" text-base bg-blue-200 rounded-lg font-semibold p-2 flex items-center"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </span>
                      <span className="ml-2 text-sm">{`Dokumen ${item.id}`}</span>
                    </button>
                  </td>
                  <td className="px-4 py-3">
                    <button
                      onClick={() => {
                        handleFileDelete(item.id);
                      }}
                      type="button"
                      className=" text-base bg-red-500 text-white rounded-lg font-semibold p-2 flex items-center "
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                        </svg>
                      </span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        );
      }
    } else {
      return (
        <tr>
          <td colSpan={10}>
            <Loader />
          </td>
        </tr>
      );
    }
  };
  const TableTugas = () => {
    if (tugas) {
      if (tugas.length > 0) {
        return (
          <>
            <thead>
              <tr className="border">
                <th className="px-4 py-3 text-left">No.</th>
                <th className="px-4 py-3 text-left">Dokumen</th>
                <th className="px-4 py-3 text-left">Hapus</th>
              </tr>
            </thead>
            <tbody>
              {tugas.map((item, index) => (
                <tr key={index}>
                  <td className="px-4 py-3 text-sm">{index + 1}.</td>
                  <td className="px-4 py-3">
                    <button
                      onClick={() => {
                        handleDownloadClick(item.attachment);
                      }}
                      type="button"
                      className=" text-base bg-blue-200 rounded-lg font-semibold p-2 w-full flex items-center"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </span>
                      <span className="ml-2 text-sm">{`${item.desc}`}</span>
                    </button>
                  </td>
                  <td className="px-4 py-3">
                    <button
                      onClick={() => {
                        handleTugasDelete(item.id);
                      }}
                      type="button"
                      className=" text-base bg-red-500 text-white rounded-lg font-semibold p-2 flex items-center "
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                        </svg>
                      </span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        );
      }
    } else {
      return (
        <tr>
          <td colSpan={10}>
            <Loader />
          </td>
        </tr>
      );
    }
  };
  return (
    <div className={`flex h-screen bg-gray-50 dark:bg-gray-900 `}>
      <Header menu="Kursus" />
      <div className="flex flex-col flex-1 w-full mt-16">
        <main className="h-full pb-16 overflow-y-auto">
          <div className="container px-6 mx-auto">
            <div className="block mt-3">
              <Link
                to={`/kursus`}
                className="flex inline-flex items-center justify-between px-4 py-2 text-xs font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
              >
                <svg
                  className="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
                <span>Kembali</span>
              </Link>
            </div>
            <div className="flex flex-wrap mt-3">
              <div className="p-4">
                <div
                  className=" bg-cover"
                  style={{
                    width: 100,
                    height: 100,
                    backgroundImage: `url('${logo
                      .replace(/\\/g, "/")
                      .replace(/\s+/g, "")}')`,
                    backgroundPositionX: "center",
                  }}
                >
                  {" "}
                </div>
              </div>
              <div className="p-4 lg:w-10/12 text-gray-700 dark:text-gray-200">
                <p className=" text-2xl font-semibold">{course.title}</p>
                <p className=" text-sm text-gray-400 leading-4">
                  {course.desc}
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-5">
              <div className="w-full  p-1">
                <div className="p-3 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                  <h4 className="mb-4 font-semibold text-gray-600 dark:text-gray-300">
                    Materi Kursus
                  </h4>
                  <div className="flex flex-col items-end mb-2">
                    <button
                      type="button"
                      onClick={() => {
                        setModalAdd(true);
                        setTitle("");
                        setEditorState(
                          EditorState.createWithContent(
                            ContentState.createFromText("")
                          )
                        );
                      }}
                      className="flex items-center justify-between px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                      </svg>
                      <span className=" ml-2"> Tambah Materi</span>
                    </button>
                  </div>
                  <div className="w-full overflow-y-auto">
                    <table className="w-full whitespace-no-wrap overflow-y-auto">
                      <thead>
                        <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                          <th className="px-4 py-3">No.</th>
                          <th className="px-4 py-3">Judul Materi</th>
                          <th className="px-4 py-3">Lampiran</th>
                          <th className="px-4 py-3">Opsi</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        <TableMateri />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="w-full p-1">
                <div className="p-3 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                  <h4 className="mb-4 font-semibold text-gray-600 dark:text-gray-300">
                    Daftar Tugas Peserta
                  </h4>
                  <div className="w-full overflow-y-auto">
                    <table className="w-full whitespace-no-wrap">
                      <thead>
                        <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                          <th className="px-4 py-3">No.</th>
                          <th>Foto</th>
                          <th className="px-4 py-3">Nama Peserta</th>
                          <th>Email</th>
                          <th>Tugas</th>
                          <th className="px-4 py-3">Opsi</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        <TableSiswa />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full overflow-hidden rounded-lg shadow-xs mt-10"></div>
          </div>
        </main>
      </div>
      <Modal
        className="modal"
        show={modalAdd}
        onHide={handleClose}
        renderBackdrop={renderBackdrop}
      >
        <div
          className=" fixed z-30 top-1.5  w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg h-screen overflow-y-auto"
          role="dialog"
          id="modal"
        >
          <header className="flex justify-end">
            <button
              onClick={() => setModalAdd(false)}
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
              </svg>
            </button>
          </header>
          <form onSubmit={handlePost}>
            <div className="mt-4 mb-6">
              <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
                Tambah Materi
              </p>
              <label className="block text-sm mt-3">
                <span className="text-gray-700 dark:text-gray-400">
                  Judul Materi
                </span>
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                />
              </label>

              <label className="block text-sm mt-3">
                <span className="text-gray-700 dark:text-gray-400">
                  Isi Materi
                </span>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  placeholder="Masukkan materi..."
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                />
              </label>
            </div>
            <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
              <button
                type="button"
                onClick={() => setModalAdd(false)}
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
              >
                Batal
              </button>
              <button
                type="submit"
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
              >
                Simpan
              </button>
            </footer>
          </form>
        </div>
      </Modal>
      <Modal
        className="modal"
        show={modalEdit}
        onHide={handleEditClose}
        renderBackdrop={renderBackdrop}
      >
        <div
          className=" fixed z-30 top-1.5  w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg h-screen overflow-y-auto"
          role="dialog"
          id="modaledit"
        >
          <header className="flex justify-end">
            <button
              onClick={() => setModalEdit(false)}
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
              </svg>
            </button>
          </header>
          <form onSubmit={handlePut}>
            <div className="mt-4 mb-6">
              <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
                Edit Materi
              </p>
              <label className="block text-sm mt-3">
                <span className="text-gray-700 dark:text-gray-400">
                  Judul Materi
                </span>
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                />
              </label>

              <label className="block text-sm mt-3">
                <span className="text-gray-700 dark:text-gray-400">
                  Isi Materi
                </span>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  placeholder="Masukkan materi..."
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                />
              </label>
            </div>
            <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
              <button
                type="button"
                onClick={() => setModalEdit(false)}
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
              >
                Batal
              </button>
              <button
                type="submit"
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
              >
                Simpan
              </button>
            </footer>
          </form>
        </div>
      </Modal>
      <Modal
        className="modal"
        show={modalFile}
        onHide={handleFileClose}
        renderBackdrop={renderBackdrop}
      >
        <div
          className=" fixed z-30 top-1.5  w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg h-screen overflow-y-auto"
          role="dialog"
          id="modaledit"
        >
          <header className="flex justify-end">
            <button
              onClick={() => setModalFile(false)}
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
              </svg>
            </button>
          </header>
          <form onSubmit={handleUpload}>
            <div className="mt-4 mb-6">
              <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
                Dokumen Lampiran Materi
              </p>
              <table className="whitespace-no-wrap mt-5 border w-full lg:w-6/12">
                <TableFile />
              </table>
              <div className="flex mt-5">
                <label className="block text-sm mt-3">
                  <span className="text-gray-700 dark:text-gray-400">
                    Upload File Baru
                  </span>
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                  />
                </label>

                <div className="flex flex-col justify-end">
                  <button
                    type="submit"
                    className="w-full ml-2 px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-3 active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
                  >
                    Tambah
                  </button>
                </div>
              </div>
            </div>
            <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
              <button
                type="button"
                onClick={() => setModalFile(false)}
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
              >
                Tutup
              </button>
            </footer>
          </form>
        </div>
      </Modal>
      <Modal
        className="modal"
        show={modalTugas}
        onHide={handleTugasClose}
        renderBackdrop={renderBackdrop}
      >
        <div
          className=" fixed z-30 top-1.5  w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg h-screen overflow-y-auto"
          role="dialog"
          id="modaledit"
        >
          <header className="flex justify-end">
            <button
              onClick={() => setModalTugas(false)}
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
              </svg>
            </button>
          </header>
          <form onSubmit={handleUploadTugas}>
            <div className="mt-4 mb-6">
              <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
                Dokumen Tugas Peserta
              </p>
              <table className="whitespace-no-wrap mt-5 border w-full lg:w-6/12">
                <TableTugas />
              </table>
              <div className="flex mt-5 flex-wrap">
                <label className="block text-sm mt-3 mr-2">
                  <span className="text-gray-700 dark:text-gray-400">
                    Upload File Baru
                  </span>
                  <input
                    type="file"
                    onChange={(e) => setFileTugas(e.target.files[0])}
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                  />
                </label>
                <label className="block text-sm mt-3 w-full">
                  <span className="text-gray-700 dark:text-gray-400">
                    Deskripsi
                  </span>
                  <textarea
                    type="text"
                    name="desc"
                    value={descTugas}
                    onChange={(e) => setDescTugas(e.target.value)}
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                  ></textarea>
                </label>

                <div className="flex flex-col justify-end mt-3">
                  <button
                    type="submit"
                    className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-3 active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
                  >
                    Tambah
                  </button>
                </div>
              </div>
            </div>
            <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
              <button
                type="button"
                onClick={() => setModalTugas(false)}
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
              >
                Tutup
              </button>
            </footer>
          </form>
        </div>
      </Modal>
      <Modal
        className="modal"
        show={modalDelete}
        onHide={handleDeleteClose}
        renderBackdrop={renderBackdrop}
      >
        <div
          className=" fixed z-30 top-1.5  px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg right-10 md:w-6/12 sm:w-full"
          role="dialog"
          id="modalhapus"
        >
          <header className="flex justify-end">
            <button
              onClick={() => setModalDelete(false)}
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
              </svg>
            </button>
          </header>
          <div>Anda yakin ingin menghapus data ini?</div>
          <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <button
              type="button"
              onClick={() => setModalDelete(false)}
              className="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
            >
              Batal
            </button>
            <button
              onClick={handleDelete}
              className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
            >
              Hapus
            </button>
          </footer>
        </div>
      </Modal>
      <Modal
        className="modal"
        show={modalDeleteSiswa}
        onHide={handleDeleteSiswaClose}
        renderBackdrop={renderBackdrop}
      >
        <div
          className=" fixed z-30 top-1.5  px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg right-10 md:w-6/12 sm:w-full"
          role="dialog"
          id="modalhapus"
        >
          <header className="flex justify-end">
            <button
              onClick={() => setModalDeleteSiswa(false)}
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
              </svg>
            </button>
          </header>
          <div>Anda yakin ingin mengeluarkan peserta ini?</div>
          <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <button
              type="button"
              onClick={() => setModalDeleteSiswa(false)}
              className="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
            >
              Batal
            </button>
            <button
              onClick={() => {
                handleDeleteSiswa();
              }}
              className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
            >
              Hapus
            </button>
          </footer>
        </div>
      </Modal>
    </div>
  );
}

export default DetailKursus;
