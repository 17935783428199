import { Link, redirect, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { stateFromHTML } from "draft-js-import-html";
import Loader from "../web/components/Loader";

export default function HasilTugasSiswa() {
  const param = useParams();
  const userID = param.user_id;
  const courseID = param.course_id;
  const [isTugasEmpty, setIsTugasEmpty] = useState(0);

  //! LIST SISWA
  const [listHasil, setlistHasil] = useState(null);
  const loadHasil = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/guest/get_answer_by_guest/${userID}/${courseID}`;

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setlistHasil(data.data);
      if (listHasil.length === 0) {
        setIsTugasEmpty(0);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const CardImage = (props) => {
    if (isImage(props.path)) {
      return (
        <div
          className=" bg-cover rounded-lg w-full bg-center flex  items-end  m-auto"
          style={{
            height: 200,
            backgroundImage: `url('${
              process.env.REACT_APP_API_BASE_URL +
              "/" +
              props.path.replace(/\\/g, "/").replace(/\s+/g, "")
            }')`,
          }}
        ></div>
      );
    } else {
      return (
        <div className="w-32 m-auto flex flex-wrap" style={{ height: 200 }}>
          <img src="/images/197-download-1.svg" />
        </div>
      );
    }
  };
  const CardHasil = () => {
    if (listHasil) {
      if (listHasil.length > 0) {
        return (
          <>
            <div className="w-full flex flex-wrap lg:w-7/12  rounded-lg">
              {listHasil.map((item, key) => (
                <div key={key} className=" w-full md:w-6/12 lg:w-4/12">
                  <div className="  bg-white m-2 rounded-lg text-center">
                    <CardImage path={item.attachment} />

                    <div className="p-3 font-semibold">{item.desc}</div>
                    <button
                      className="border border-black hover:bg-red-500 hover:text-white mx-auto p-1 self-start hover:border-none mb-2 px-5"
                      onClick={() => {
                        handleDownloadClick(item.attachment);
                      }}
                    >
                      Download
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className={`hidden md:block w-5/12 lg:4/12 p-5`}>
              <img alt="" src="/images/File synchronization-pana.svg" />
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className=" w-full lg:w-7/12 m-auto">
              <p className=" text-center font-semibold text-xl">Data Kosong</p>
              <img src="/images/Working from anywhere-pana.svg" />
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <Loader />
        </>
      );
    }
  };

  //! FILE
  const isImage = (path) => {
    const validExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = path.split(".").pop().toLowerCase();
    return validExtensions.includes(fileExtension);
  };

  const handleDownloadClick = async (filePath) => {
    try {
      console.log(filePath);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/" + filePath
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const filename = filePath.split("/").pop();
      a.href = url;
      a.download = filename; // Set the desired filename
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  useEffect(() => {
    loadHasil();
  }, []);

  return (
    <>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap"
        rel="stylesheet"
      />
      <link href="landing/css/fontawesome-all.css" rel="stylesheet" />
      <link
        href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css"
        rel="stylesheet"
      />
      <link href="landing/css/swiper.css" rel="stylesheet" />
      <link href="landing/css/magnific-popup.css" rel="stylesheet" />
      <link href="landing/css/styles.css" rel="stylesheet" />

      <div className="p-5 bg-red-500 flex">
        <div className="w-4/12">
          <Link
            to={`/siswa-course/${courseID}`}
            className=" inline-flex items-center justify-between px-4 py-2 text-xs font-medium leading-5 text-white transition-colors duration-150 bg-red-800 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
          >
            <svg
              className="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <span>Kembali</span>
          </Link>
        </div>

        <div className="w-4/12 text-center text-white font-semibold text-lg">
          Hasil Tugas Peserta
        </div>
      </div>
      <div className="p-5 flex header">
        <CardHasil />
      </div>

      <script src="landing/js/jquery.min.js"></script>
      <script src="landing/js/jquery.easing.min.js"></script>
      <script src="landing/js/swiper.min.js"></script>
      <script src="landing/js/jquery.magnific-popup.js"></script>
      <script src="landing/js/scripts.js"></script>
    </>
  );
}
