import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WebToast from "../components/WebToast";

export default function LoginGuru() {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const jsonData = {};
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });

    const url = `${process.env.REACT_APP_API_BASE_URL}/login`;
    console.log(url);
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(jsonData),
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data.role);
        if (data.role == 2) {
          WebToast("error", "Anda tidak memiliki akses fasilitator");
          navigate("/login");
        } else {
          navigate("/dashboard");
        }
      } else {
        WebToast("error", data.message);
      }
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              className="object-cover w-full h-full dark:hidden"
              src="images/login-office.jpeg"
              alt="Office"
            />
            <img
              aria-hidden="true"
              className="hidden object-cover w-full h-full dark:block"
              src="images/login-office-dark.jpeg"
              alt="Office"
            />
          </div>
          <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Login sebagai Fasilitator
              </h1>
              <form onSubmit={handleSubmit}>
                <label className="block text-sm">
                  <span className="text-gray-700 dark:text-gray-400">
                    Email
                  </span>
                  <input
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-red-400 focus:outline-none focus:shadow-outline-red dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    name="email"
                    placeholder="Email"
                  />
                </label>
                <label className="block mt-4 text-sm">
                  <span className="text-gray-700 dark:text-gray-400">
                    Password
                  </span>
                  <input
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-red-400 focus:outline-none focus:shadow-outline-red dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    placeholder="***************"
                    type="password"
                    name="password"
                  />
                </label>

                <button
                  type="submit"
                  className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-red"
                >
                  Log in
                </button>
              </form>
              <hr className="my-8" />

              <p className="mt-1">
                <Link
                  to={`/registerguru`}
                  className="text-sm font-medium text-red-500 dark:text-red-400 hover:underline"
                >
                  Buat Akun
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
