import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Redirect,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
// APP
import Login from "./pages/auth/Login";
import Home from "./pages/Home";
import Register from "./pages/auth/Register";
import User from "./pages/User";
import Explore from "./pages/Explore";
import Landing from "./pages/Landing";
import Error from "./pages/Error";
import PotensiMobile from "./pages/PotensiMobile";
import DetailPotensiMobile from "./pages/DetailPotensiMobile";
import DetailExplore from "./pages/DetailExplore";
import DetailCourse from "./pages/DetailCourse";
import DetailMateri from "./pages/DetailMateri";
import DetailTugas from "./pages/DetailTugas";
import ListSiswaMobile from "./pages/ListSiswaMobile";
import ListDokumenMobile from "./pages/ListDokumenMobile";
// WEB

import LoginGuru from "./web/pages/LoginGuru";
import RegisterGuru from "./web/pages/RegisterGuru";
import Dashboard from "./web/pages/Dashboard";
import Profil from "./web/pages/Profil";
import Kursus from "./web/pages/Kursus";
import Potensi from "./web/pages/Potensi";
import DetailKursus from "./web/pages/DetailKursus";
import DetailPotensi from "./pages/DetailPotensi";
import ListSiswaCourse from "./pages/ListSiswaCourse";
import HasilTugasSiswa from "./pages/HasilTugasSiswa";

import "./index.css";
import "./toast.css";
import { useState, createContext, useContext } from "react";

function App() {
  const initSidebar = () => {
    if (window.innerWidth <= 800) {
      return false;
    } else {
      return true;
    }
  };
  const TitleContext = createContext();

  return (
    <>
      <Routes>
        {/* LANDING */}
        <Route path="" Component={Landing} />
        <Route path="/login" Component={Login} />
        <Route path="/register" Component={Register} />
        <Route path="/detailpotensi/:id" Component={DetailPotensi} />
        <Route path="/siswa-course/:id" Component={ListSiswaCourse} />
        <Route
          path="/hasil-tugas-siswa/:user_id/:course_id"
          Component={HasilTugasSiswa}
        />

        {/* APP */}
        <Route path="/home" Component={Home} />
        <Route path="/user" Component={User} />
        <Route path="/explore" Component={Explore} />
        <Route path="/potensimobile" Component={PotensiMobile} />
        <Route
          path="/detailpotensimobile/:id"
          Component={DetailPotensiMobile}
        />
        <Route path="/detailexplore/:id" Component={DetailExplore} />
        <Route path="/detailcourse/:id" Component={DetailCourse} />
        <Route path="/detailmateri/:id" Component={DetailMateri} />
        <Route path="/detailtugas/:id" Component={DetailTugas} />
        <Route path="/daftarsiswa/:id" Component={ListSiswaMobile} />
        <Route
          path="/dokumensiswa/:user_id/:course_id"
          Component={ListDokumenMobile}
        />
        <Route path="/error" Component={Error} />
        {/* WEB */}
        <Route path="/loginguru" Component={LoginGuru} />
        <Route path="/registerguru" Component={RegisterGuru} />
        <Route path="/dashboard" Component={Dashboard} />
        <Route path="/profil" Component={Profil} />
        <Route path="/kursus" Component={Kursus} />
        <Route path="/potensi" Component={Potensi} />
        <Route path="/detailkursus/:id" Component={DetailKursus} />
      </Routes>

      <ToastContainer />
    </>
  );
}

export default App;
