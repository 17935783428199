import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import WebToast from "../components/WebToast";

export default function Dashboard() {
  let isSideMenuOpen = true;
  const navigate = useNavigate();
  const baseAPI = process.env.REACT_APP_API_BASE_URL;
  //! STATE
  const [totalCourse, setTotalCourse] = useState(0);
  const [totalSiswa, setTotalSiswa] = useState(0);
  const [totalMateri, setTotalMateri] = useState(0);
  const [totalPotensi, setTotalPotensi] = useState(0);

  const getTotalCourse = async () => {
    try {
      const response = await fetch(baseAPI + "/dashboard_guru/total_kursus", {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
        return false;
      }
      setTotalCourse(data.data);
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const getTotalSiswa = async () => {
    try {
      const response = await fetch(baseAPI + "/dashboard_guru/total_siswa", {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
        return false;
      }
      setTotalSiswa(data.data);
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const getTotalMateri = async () => {
    try {
      const response = await fetch(baseAPI + "/dashboard_guru/total_materi", {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
        return false;
      }
      setTotalMateri(data.data);
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const getTotalPotensi = async () => {
    try {
      const response = await fetch(baseAPI + "/dashboard_guru/total_potensi", {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
        return false;
      }
      setTotalPotensi(data.data);
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };

  useEffect(() => {
    getTotalCourse();
    getTotalMateri();
    getTotalSiswa();
    getTotalPotensi();
  }, []);

  return (
    <div
      className={`flex h-screen bg-gray-50 dark:bg-gray-900 ${
        isSideMenuOpen ? "overflow-hidden" : ""
      }`}
    >
      <Header menu="Dashboard" />
      <div className="flex flex-col flex-1 w-full mt-16">
        <main className="h-full overflow-y-auto">
          <div className="container px-6 mx-auto grid">
            <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
              Dashboard
            </h2>

            <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
              <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100 dark:bg-orange-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-bookmark-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                  </svg>
                </div>
                <div>
                  <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                    Total Kursus
                  </p>
                  <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                    {totalCourse}
                  </p>
                </div>
              </div>
              <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-journal-richtext"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.5 3.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047L11 4.75V7a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 7v-.5s1.54-1.274 1.639-1.208zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                    <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                    <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                  </svg>
                </div>
                <div>
                  <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                    Total Materi
                  </p>
                  <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                    {totalMateri}
                  </p>
                </div>
              </div>
              <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-collection"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13z" />
                  </svg>
                </div>
                <div>
                  <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                    Total Potensi
                  </p>
                  <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                    {totalPotensi}
                  </p>
                </div>
              </div>
              <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                <div className="p-3 mr-4 text-teal-500 bg-teal-100 rounded-full dark:text-teal-100 dark:bg-teal-500">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                  </svg>
                </div>
                <div>
                  <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                    Total Peserta
                  </p>
                  <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                    {totalSiswa}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
