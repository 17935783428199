import { Link } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";
import { useState, useEffect } from "react";
import Loader from "../components/Loader";

export default function PotensiMobile() {
  //! POTENSI
  const [listPotensi, setListPotensi] = useState(null);
  const base_API = process.env.REACT_APP_API_BASE_URL;
  const loadPotensi = async () => {
    try {
      setListPotensi(null);
      const url = `${process.env.REACT_APP_API_BASE_URL}/users_potensi/potensi`;

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setListPotensi(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const CardPotensi = () => {
    if (listPotensi) {
      if (listPotensi.length > 0) {
        return (
          <>
            {listPotensi.map((item, index) => {
              return (
                <div className="w-6/12 p-2" key={index}>
                  <Link to={`/detailpotensimobile/${item.id}`}>
                    <div className="bg-white dark:text-black p-2 rounded-lg ">
                      <div
                        className=" bg-cover bg-center m-auto rounded-lg"
                        style={{
                          height: 120,
                          backgroundImage: `url('${
                            base_API +
                            "/" +
                            item.thumbnail
                              .replace(/\\/g, "/")
                              .replace(/\s+/g, "")
                          }')`,
                        }}
                      ></div>
                      <div className=" text-sm font-semibold mt-1">
                        {item.title}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </>
        );
      } else {
        return (
          <>
            <div className=" w-full lg:w-7/12 m-auto bg-white p-4">
              <p className=" text-center text-sm">Belum ada data</p>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <Loader />
        </>
      );
    }
  };

  //! COURSE
  const [courses, setCourses] = useState(null);
  const loadCourse = async () => {
    try {
      setCourses(null);
      const url = `${process.env.REACT_APP_API_BASE_URL}/guest/course_by_guest`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setCourses(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const CardCourse = () => {
    if (courses) {
      if (courses.length > 0) {
        return (
          <>
            {courses.map((item, index) => {
              return (
                <div className="w-6/12 p-2" key={index}>
                  <Link to={`/daftarsiswa/${item.id}`}>
                    <div className="bg-white text-black rounded-md shadow-md p-4">
                      <div
                        className=" bg-cover rounded-full bg-center m-auto"
                        style={{
                          width: 70,
                          height: 70,
                          backgroundImage: `url('${
                            base_API +
                            "/" +
                            item.logo.replace(/\\/g, "/").replace(/\s+/g, "")
                          }')`,
                        }}
                      ></div>
                      <div className="text-center font-semibold mt-3">
                        {item.title}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </>
        );
      } else {
        return (
          <>
            <div className=" w-full lg:w-7/12 m-auto bg-white p-4">
              <p className=" text-center font-semibold text-base">
                Tidak ada data kursus
              </p>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <Loader />
        </>
      );
    }
  };
  useEffect(() => {
    loadPotensi();
    loadCourse();
  }, []);
  return (
    <main className=" min-h-screen flex-col items-center justify-between bg-gray-100 dark:text-white dark:bg-gray-800">
      <div className="-mt-5 px-1">
        <img className="w-full" src="/images/headerpotensi.svg" />
      </div>
      <div className="mt-3 px-3">
        <div className=" font-semibold text-lg">Potensi Desa</div>
        <div className="flex flex-wrap mt-3">
          <CardPotensi />
        </div>
        <div className=" font-semibold text-lg mt-5">Hasil Pelatihan</div>
        <div className="flex flex-wrap mt-3">
          <CardCourse />
        </div>
      </div>
      <Nav menu="potential" />
    </main>
  );
}
