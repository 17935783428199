import { Link, useParams } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";
import { useState, useEffect } from "react";
import Titlebar from "../components/Titlebar";

export default function DetailPotensiMobile() {
  const param = useParams();
  const potensiID = param.id;
  //! POTENSI
  const [potensi, setPotensi] = useState([]);
  const [thumbnail, setThumbnail] = useState("");
  const base_API = process.env.REACT_APP_API_BASE_URL;
  const loadPotensi = async () => {
    try {
      const url = `${base_API}/users_potensi/potensi_detail/${potensiID}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setPotensi(data.data);
      setThumbnail(base_API + "/" + data.data.thumbnail);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadPotensi();
  }, []);
  return (
    <main className=" min-h-screen flex-col items-center justify-between bg-gray-100 dark:text-white dark:bg-gray-800">
      <Titlebar menu="Potensi Desa" />
      <div className="pt-16 px-3">
        <div
          className=" block m-auto rounded-lg bg-red-700 bg-cover bg-center"
          style={{
            height: 300,
            backgroundImage: `url('${thumbnail
              .replace(/\\/g, "/")
              .replace(/\s+/g, "")}')`,
          }}
        ></div>{" "}
        <div className=" font-semibold text-2xl mt-4">{potensi.title}</div>
        <div
          className="mt-3"
          dangerouslySetInnerHTML={{ __html: potensi.desc }}
        ></div>
      </div>
      <Nav menu="potential" />
    </main>
  );
}
