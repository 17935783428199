import { Link, redirect, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { stateFromHTML } from "draft-js-import-html";

export default function DetailPotensi() {
  const param = useParams();
  const potensiID = param.id;

  //! DETAIL POTENSI
  const [potensi, setPotensi] = useState([]);
  const [header, setHeader] = useState("");
  const loadDetailPotensi = async (id) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/guest/detai_potention_by_guest/${id}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setHeader(
        process.env.REACT_APP_API_BASE_URL +
          "/" +
          data.data.thumbnail.replace(/\\/g, "/").replace(/\s+/g, "")
      );
      setPotensi(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  //! LIST POTENSI
  //! POTENSI
  const [listPotensi, setListPotensi] = useState([]);
  const loadPotensi = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/guest/potention_by_guest`;

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setListPotensi(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const CardPotensi = () => {
    return (
      <>
        {listPotensi.map((item, key) => (
          <Link
            key={key}
            onClick={() => {
              loadDetailPotensi(item.id);
            }}
            to={`/detailpotensi/${item.id}`}
          >
            <div className="flex flex-wrap mt-4 border rounded">
              <div
                className=" bg-cover bg-center flex items-end border"
                style={{
                  minWidth: 100,
                  minHeight: 100,
                  backgroundImage: `url('${
                    process.env.REACT_APP_API_BASE_URL +
                    "/" +
                    item.thumbnail.replace(/\\/g, "/").replace(/\s+/g, "")
                  }')`,
                }}
              ></div>
              <div className="p-3 font-semibold">{item.title}</div>
            </div>
          </Link>
        ))}
      </>
    );
  };

  useEffect(() => {
    loadPotensi();
    loadDetailPotensi(potensiID);
  }, []);

  return (
    <>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap"
        rel="stylesheet"
      />
      <link href="landing/css/fontawesome-all.css" rel="stylesheet" />
      <link
        href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css"
        rel="stylesheet"
      />
      <link href="landing/css/swiper.css" rel="stylesheet" />
      <link href="landing/css/magnific-popup.css" rel="stylesheet" />
      <link href="landing/css/styles.css" rel="stylesheet" />

      <div className="p-5 bg-red-500 flex">
        <div className="w-4/12">
          <Link
            to="/"
            className="flex inline-flex items-center justify-between px-4 py-2 text-xs font-medium leading-5 text-white transition-colors duration-150 bg-red-800 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
          >
            <svg
              className="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <span>Kembali</span>
          </Link>
        </div>
        <div className="w-4/12 text-center text-white font-semibold text-lg">
          Detail Potensi
        </div>
      </div>
      <div className="flex flex-wrap mt-16">
        <div className="w-full lg:w-8/12 border-r px-3">
          <div
            className=" bg-cover bg-center flex items-end border"
            style={{
              minWidth: 200,
              minHeight: 450,
              backgroundImage: `url('${header}')`,
            }}
          ></div>
          <div className="p-4">
            <h1 className="text-3xl my-4 font-semibold">{potensi.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: potensi.desc }}></div>
          </div>
        </div>
        <div className="w-full lg:w-4/12 border-r px-3">
          <h3 className="font-semibold text-lg text-red-600">
            Potensi Lainnya
          </h3>
          <CardPotensi />
        </div>
      </div>

      <script src="landing/js/jquery.min.js"></script>
      <script src="landing/js/jquery.easing.min.js"></script>
      <script src="landing/js/swiper.min.js"></script>
      <script src="landing/js/jquery.magnific-popup.js"></script>
      <script src="landing/js/scripts.js"></script>
    </>
  );
}
