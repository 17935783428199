import { Link, useParams } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";
import { useState, useEffect } from "react";
import Titlebar from "../components/Titlebar";
import Loader from "../components/Loader";

export default function ListDokumenMobile() {
  const base_API = process.env.REACT_APP_API_BASE_URL;
  const params = useParams();
  //! DOKUMEN
  const [dokumen, setDokumen] = useState(null);
  const loadDokumen = async () => {
    try {
      setDokumen(null);
      const url = `${process.env.REACT_APP_API_BASE_URL}/guest/get_answer_by_guest/${params.user_id}/${params.course_id}`;

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setDokumen(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  //! FILE
  const isImage = (path) => {
    const validExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = path.split(".").pop().toLowerCase();
    return validExtensions.includes(fileExtension);
  };
  const CardImage = (props) => {
    if (isImage(props.path)) {
      return (
        <div
          className=" bg-cover rounded-lg w-full bg-center flex  items-end  m-auto"
          style={{
            height: 150,
            backgroundImage: `url('${
              process.env.REACT_APP_API_BASE_URL +
              "/" +
              props.path.replace(/\\/g, "/").replace(/\s+/g, "")
            }')`,
          }}
        ></div>
      );
    } else {
      return (
        <div className="w-32 m-auto flex flex-wrap" style={{ height: 150 }}>
          <img src="/images/197-download-1.svg" />
        </div>
      );
    }
  };
  const CardSiswa = () => {
    if (dokumen) {
      if (dokumen.length > 0) {
        return (
          <>
            <div className=" flex flex-wrap w-full box-border  rounded-lg">
              {dokumen.map((item, key) => (
                <div key={key} className=" w-6/12 p-2">
                  <div className="  bg-white rounded-lg text-center">
                    <CardImage path={item.attachment} />

                    <div className="p-3 font-semibold">{item.desc}</div>
                    <button
                      className="border border-black hover:bg-red-500 hover:text-white mx-auto p-1 self-start hover:border-none mb-2 px-5"
                      onClick={() => {
                        handleDownloadClick(item.attachment);
                      }}
                    >
                      Download
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className=" w-full lg:w-7/12 m-auto bg-white p-4">
              <p className=" text-center font-semibold text-base">
                Belum ada dokumen hasil tugas
              </p>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <Loader />
        </>
      );
    }
  };
  const handleDownloadClick = async (filePath) => {
    try {
      console.log(filePath);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/" + filePath
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const filename = filePath.split("/").pop();
      a.href = url;
      a.download = filename; // Set the desired filename
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  useEffect(() => {
    loadDokumen();
  }, []);
  return (
    <main className=" min-h-screen flex-col items-center justify-between bg-gray-100 dark:bg-gray-800">
      <Titlebar menu="Hasil Tugas Peserta" />

      <div className="mt-3 px-1 pt-12">
        <CardSiswa />
      </div>
      <Nav menu="potential" />
    </main>
  );
}
