import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WebToast from "../components/WebToast";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useEffect, useState, useRef, useContext } from "react";

export default function Profil() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [hidePasswordForm, sethideStatus] = useState("hidden");
  const [fullname, setFullname] = useState("");
  const [avatar, setAvatar] = useState("");
  const [file, setFile] = useState("");
  const [email, setEmail] = useState("");
  const url = `${process.env.REACT_APP_API_BASE_URL}/user/getself_user`;

  const handleChange = () => {
    if (hidePasswordForm == "hidden") {
      sethideStatus("block");
    } else {
      sethideStatus("hidden");
    }
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const form = event.target;
      const formData = new FormData(form);
      if (file) {
        formData.append("file", file);
      }
      console.log(formData);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/user/update_user`,
        {
          method: "PUT",
          body: formData,
          credentials: "include",
        }
      );

      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        setAvatar(`${process.env.REACT_APP_API_BASE_URL}/${data.data.avatar}`);
        fileInputRef.current.value = "";

        WebToast("success", data.message);
      } else {
        WebToast("error", data.message);
      }
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };

  const init = async () => {
    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();

      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
        return false;
      }

      setFullname(data.data.fullname);
      setEmail(data.data.email);
      setAvatar(`${process.env.REACT_APP_API_BASE_URL}/${data.data.avatar}`);
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <div className={`flex h-screen bg-gray-50 dark:bg-gray-900 `}>
        <Header menu="Pengaturan Profil" />
        <div className="flex flex-col flex-1 w-full mt-16">
          <main className="h-full overflow-y-auto">
            <div className="container px-6 mx-auto grid">
              <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                Pengaturan profil
              </h2>
              <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="flex justify-center mb-4">
                  <div
                    className=" bg-cover rounded-full bg-center"
                    style={{
                      width: 100,
                      height: 100,
                      backgroundImage: `url('${avatar
                        .replace(/\\/g, "/")
                        .replace(/\s+/g, "")}')`,
                    }}
                  ></div>
                </div>
                <form encType="multipart/formData" onSubmit={handleSubmit}>
                  <label className="block text-sm">
                    <span className="text-gray-700 dark:text-gray-400">
                      Nama Lengkap
                    </span>
                    <input
                      type="text"
                      name="fullname"
                      onChange={(e) => setFullname(e.target.value)}
                      className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                      value={fullname}
                    />
                  </label>
                  <label className="block text-sm mt-4">
                    <span className="text-gray-700 dark:text-gray-400">
                      Email
                    </span>
                    <input
                      name="email"
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                      value={email}
                    />
                  </label>
                  <label className="block text-sm mt-4">
                    <span className="text-gray-700 dark:text-gray-400">
                      Ubah Foto Profil
                    </span>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={(e) => {
                        const file = e.target.files[0]; // Get the selected file
                        setFile(file);
                      }}
                      className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    />
                  </label>
                  <div className="mt-3">
                    <button
                      type="button"
                      onClick={handleChange}
                      className=" text-sm text-red-500 font-semibold"
                    >
                      Ubah Password?
                    </button>
                  </div>
                  <label className={`text-sm mt-4 ${hidePasswordForm}`}>
                    <span className="text-gray-700 dark:text-gray-400">
                      Password Lama
                    </span>
                    <input
                      type="password"
                      name="old_password"
                      className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    />
                  </label>
                  <label className={`text-sm mt-4 ${hidePasswordForm}`}>
                    <span className="text-gray-700 dark:text-gray-400">
                      Password Baru
                    </span>
                    <input
                      type="password"
                      name="password"
                      className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    />
                  </label>{" "}
                  <label className={`text-sm mt-4 ${hidePasswordForm}`}>
                    <span className="text-gray-700 dark:text-gray-400">
                      Konfirmasi Password Baru
                    </span>
                    <input
                      type="password"
                      name="password_confirm"
                      className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    />
                  </label>
                  <button
                    type="submit"
                    className="bg-red-500 py-2 px-6 text-sm text-white rounded mt-4"
                  >
                    Simpan
                  </button>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
