import { Link, useParams, useNavigate, json } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";
import { useState, useEffect } from "react";
import Titlebar from "../components/Titlebar";
import { toast } from "react-toastify";

export default function DetailMateri() {
  const param = useParams();
  const lessonID = param.id;
  const navigate = useNavigate();
  const base_API = process.env.REACT_APP_API_BASE_URL;

  //!MATERI
  const [materi, setMateri] = useState([]);
  const loadMateri = async () => {
    try {
      const url = `${base_API}/users_lesson/detail/${lessonID}`;
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
      });
      const data = await response.json();
      setMateri(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // ! DOKUMEN
  const [lampiran, setlampiran] = useState(null);
  const loadLampiran = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/users_lesson/get_document/${lessonID}`;

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setlampiran(data.data);
      if (response.status == 401) {
        toast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
        return false;
      }
    } catch (error) {
      toast("error", "Sistem Error");
    }
  };

  const DokumenPendukung = () => {
    if (lampiran != null && lampiran.length > 0) {
      return (
        <>
          <div className="mt-10 bg-slate-100 rounded dark:text-black">
            <div className="flex items-center">
              <div>
                <img src="/images/Pin in circle.svg" />
              </div>
              <div className=" font-semibold px-2 text-lg">
                Materi Pendukung
              </div>
            </div>
            <div className="p-5">
              {lampiran.map((item, index) => {
                return (
                  <button
                    key={index}
                    onClick={() => {
                      handleDownloadClick(item.filename);
                    }}
                    className="flex text-left items-center w-full font-semibold p-2 border-b"
                  >
                    <img src="/images/Download.svg" className="mr-2" />
                    Lampiran {item.id}
                  </button>
                );
              })}
            </div>
          </div>
        </>
      );
    }
  };

  const handleDownloadClick = async (filePath) => {
    try {
      console.log(filePath);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/" + filePath
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const filename = filePath.split("/").pop();
      a.href = url;
      a.download = filename; // Set the desired filename
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  //!SELESAIKAN MATERI
  const selesaikanMateri = async () => {
    try {
      const response = await fetch(
        `${base_API}/users_lesson/finish/${lessonID}`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
      } else if (response.ok) {
        Toast("success", data.message);
        navigate("/detailcourse/" + materi.CourseID);
      } else {
        Toast("error", data.message);
      }
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };

  useEffect(() => {
    loadMateri();
    loadLampiran();
  }, []);
  return (
    <main className=" min-h-screen flex-col items-center justify-between bg-white dark:text-white dark:bg-gray-800">
      <Titlebar menu="Lihat Materi" />
      <div className="p-3  pt-16">
        <div className="mt-5 font-semibold mb-2 text-2xl">{materi.title}</div>

        <div
          className=" text-gray-600 text-base leading-7 dark:text-white"
          dangerouslySetInnerHTML={{ __html: materi.content }}
        ></div>
        <DokumenPendukung />
      </div>
      <button
        onClick={selesaikanMateri}
        className="p-2 border rounded btn-orange mx-4 w-6/12 fixed bottom-0 mb-16"
        style={{ width: "90%" }}
      >
        <div className="flex justify-center">Selesaikan Materi</div>
      </button>
      <Nav menu="home" />
    </main>
  );
}
