import Toast from "../../components/Toast";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth >= 800) navigate("/error");
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const jsonData = {};
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });

    const url = `${process.env.REACT_APP_API_BASE_URL}/login`;
    console.log(url);
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(jsonData),
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data.role);
        if (data.role == 1) {
          Toast(
            "error",
            "Anda tidak memiliki ke akun peserta, silahkan login sebagai fasilitator"
          );
          navigate("/loginguru");
        } else {
          navigate("/home");
        }
      } else {
        Toast("error", data.message);
      }
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };
  const TogglePasswordButton = () => {
    const [imageSrc, setIconSrc] = useState("images/eye.png");
    const [hideStatus, sethideStatus] = useState(true);
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
      sethideStatus(hideStatus == true ? false : true);
      if (hideStatus == false) {
        setIconSrc("images/hide.png");
        setPasswordType("text");
      } else {
        setPasswordType("password");
        setIconSrc("images/eye.png");
      }
    };

    return (
      <div className="border-b-2 w-11/12 flex">
        <input
          type={passwordType}
          placeholder="Password"
          name="password"
          className=" h-10 focus:outline-none w-10/12 dark:bg-gray-800 dark:text-white"
        />
        <div className="w-2/12 flex justify-end items-center">
          <div>
            <img src={imageSrc} alt="Image" onClick={togglePassword} />
          </div>
        </div>
      </div>
    );
  };
  return (
    <main className="  min-h-screen flex-col items-center justify-between bg-white dark:bg-gray-900">
      <ToastContainer />

      <div className="bg-gradient-orange rounded-b-3xl">
        <div className="text-right flex justify-end p-3">
          <img src="/logo.png" width={50} />
        </div>
        <div className=" flex justify-center px-10">
          <img src="images/viewing-an-educational-video-on-holographic-screen.svg" />
        </div>
      </div>
      <div className=" container px-7">
        <p className=" text-4xl font-bold pt-20 dark:text-white">Login</p>
        <form className=" mt-7" onSubmit={handleSubmit}>
          <div className=" flex w-full py-2">
            <div className="flex w-1/12 justify-center">
              <img src="images/at-sign.svg"></img>
            </div>
            <input
              type="text"
              name="email"
              placeholder="Alamat Email"
              className="w-11/12 h-10 focus:outline-none border-b-2 dark:bg-gray-800 dark:text-white "
            />
          </div>
          <div className=" flex w-full py-2">
            <div className="flex w-1/12 justify-center">
              <img src="images/lock.svg"></img>
            </div>
            <TogglePasswordButton />
          </div>
          <input type="submit" className="btn-orange mt-5" value="Login" />
        </form>
        <div className="fixed bottom-0 inset-x-0 flex justify-center items-end h-20 p-4">
          <p className="dark:text-white">
            Belum memiliki akun?
            <Link to={`/register`} className=" font-semibold">
              {" "}
              Daftar sekarang!
            </Link>
          </p>
        </div>
      </div>
    </main>
  );
}

export default Login;
