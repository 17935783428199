import { ToastContainer, toast } from 'react-toastify';
export default function (style, message) {
  const toast_setting = {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    theme: "colored",
    toastId: 'success1',
  }
  if (style == 'success') {
    toast.success(message, toast_setting);
  } else {
    toast.error(message, toast_setting);
  }
}