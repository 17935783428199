import { Link, redirect } from "react-router-dom";
import { useState, useEffect } from "react";

export default function Landing() {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [refresh, setRefreshCount] = useState(1);

  //! POTENSI
  const [listPotensi, setListPotensi] = useState([]);
  const loadPotensi = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/guest/potention_by_guest`;

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setListPotensi(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const CardPotensi = () => {
    return (
      <>
        {listPotensi.map((item, key) => (
          <div className=" w-full sm:w-6/12 lg:w-4/12 p-2" key={key}>
            <Link to={`/detailpotensi/${item.id}`}>
              <div
                className=" bg-cover bg-center rounded-t-lg flex items-end border"
                style={{
                  minWidth: 200,
                  minHeight: 400,
                  backgroundImage: `url('${
                    process.env.REACT_APP_API_BASE_URL +
                    "/" +
                    item.thumbnail.replace(/\\/g, "/").replace(/\s+/g, "")
                  }')`,
                }}
              >
                <div className="bg-white p-4 text-black w-full font-semibold">
                  {item.title}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </>
    );
  };

  //! COURSE
  const [listCourse, setListCourse] = useState([]);
  const loadCourse = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/guest/course_by_guest`;

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setListCourse(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const CardCourse = () => {
    return (
      <>
        {listCourse.map((item, key) => (
          <div className=" w-full sm:w-6/12 lg:w-3/12 p-2 mt-5 " key={key}>
            <Link to={`/siswa-course/${item.id}`}>
              <div className="text-black bg-white rounded-lg hover:text-white hover:bg-orange-700 p-3">
                <div
                  className="m-auto bg-cover bg-center border-none rounded-full flex items-end border"
                  style={{
                    width: 150,
                    height: 150,
                    backgroundImage: `url('${
                      process.env.REACT_APP_API_BASE_URL +
                      "/" +
                      item.logo.replace(/\\/g, "/").replace(/\s+/g, "")
                    }')`,
                  }}
                ></div>
                <div className=" p-4 w-full font-semibold">{item.title}</div>
              </div>
            </Link>
          </div>
        ))}
      </>
    );
  };

  useEffect(() => {
    loadPotensi();
    loadCourse();
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const handleInstall = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  return (
    <>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap"
        rel="stylesheet"
      />
      <link href="landing/css/fontawesome-all.css" rel="stylesheet" />
      <link
        href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css"
        rel="stylesheet"
      />
      <link href="landing/css/swiper.css" rel="stylesheet" />
      <link href="landing/css/magnific-popup.css" rel="stylesheet" />
      <link href="landing/css/styles.css" rel="stylesheet" />

      <header
        id="header"
        className="header py-28 text-center md:pt-36 lg:text-left xl:pt-16 xl:pb-32"
      >
        <div className="container px-4 sm:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
          <div className="mb-16 lg:mt-32 xl:mt-40 xl:mr-12">
            <h1 className="h1-large mb-5">
              Kuasai Potensi Anda dengan Aplikasi Kursus Bimbingan Masyarakat
            </h1>
            <p className="p-large mb-8 text-gray-600">
              Dengan pembelajaran yang mudah dan inovatif, Anda dapat
              meningkatkan keterampilan dan pengetahuan Anda secara efektif dan
              efisien.
            </p>
            <a
              onClick={handleInstall}
              className=" btn-solid-lg "
              href="#your-link"
            >
              Install Aplikasi
            </a>
          </div>
          <div className="xl:text-right flex items-center">
            <div>
              <img
                src="images/4470745_2345124.jpg"
                className=" rounded-3xl"
                alt="alternative"
              />
            </div>
          </div>
        </div>
      </header>
      <div className="py-24">
        <div className="container px-4 sm:px-8 lg:grid lg:grid-cols-12 lg:gap-x-12">
          <div className="lg:col-span-7">
            <div className="mb-12 lg:mb-0 xl:mr-14 flex">
              <img
                className="inline"
                src="images/Brainstorming-cuate.svg"
                alt="alternative"
              />
            </div>
          </div>
          <div className="lg:col-span-5">
            <div className="xl:mt-12">
              <h2 className="mb-6">Mau belajar kapan saja, di mana saja?</h2>
              <ul className="list mb-7 space-y-2">
                <li className="flex">
                  <i className="fas fa-chevron-right"></i>
                  <div>
                    Fitur pencari kursus untuk membantu Anda menemukan kursus
                    yang sesuai dengan minat dan kebutuhan Anda.
                  </div>
                </li>
                <li className="flex">
                  <i className="fas fa-chevron-right"></i>
                  <div>
                    Metode pembelajaran yang mudah dan inovatif membuat kursus
                    lebih menarik dan menyenangkan.
                  </div>
                </li>
                <li className="flex">
                  <i className="fas fa-chevron-right"></i>
                  <div>
                    Materi kursus yang berkualitas dan relevan dengan kebutuhan
                    Anda.
                  </div>
                </li>
              </ul>
              <div className="flex">
                <Link
                  to={`/login`}
                  className="bg-red-500 border border-red-500 hover:bg-white hover:border-red-500 hover:text-red-500 mr-7 py-3 px-10 rounded-3xl text-sm text-white "
                  href="#your-link"
                >
                  Login Peserta
                </Link>
                <Link
                  to={`/loginguru`}
                  className="bg-red-500 border border-red-500 hover:bg-white hover:border-red-500 hover:text-red-500 mr-7 py-3 px-10 rounded-3xl text-sm text-white"
                  href="#your-link"
                >
                  Login Fasilitator
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="features" className="cards-1 bg-gradient-orange rounded-t-3xl">
        <div className=" font-semibold text-white text-2xl mx-4 mt-5">
          Hasil Pelatihan
        </div>
        <div className="container px-4 sm:px-8 xl:px-4 flex flex-wrap pb-24">
          <CardCourse />
        </div>
      </div>
      <div id="features" className="cards-1 my-10">
        <div className=" font-semibold text-black text-2xl mx-4 ">
          Potensi Desa
        </div>
        <div className="container px-4 sm:px-8 xl:px-4 flex flex-wrap mt-4">
          <CardPotensi />
        </div>
      </div>

      <div id="features" className="cards-1 bg-gradient-orange rounded-t-3xl">
        <div className=" font-semibold text-white text-2xl mx-4 mt-5">
          Tim Pengembang
        </div>
        <div className="container px-4 sm:px-8 xl:px-4 flex flex-wrap pb-24 justify-center">
          <div className="text-black bg-white rounded-lg  m-2">
            <div
              className="m-auto bg-cover bg-center border-none rounded-lg  flex items-end border"
              style={{
                width: 300,
                height: 400,
                backgroundImage: `url('images/Kukuh-Miroso-Raharjo-S.Pd-M.Pd-SekDep-PLS1-edited.png')`,
              }}
            ></div>
            <div className=" p-4 w-full font-semibold">
              Kukuh Miroso Raharjo, S.Pd, M.Pd
            </div>
          </div>
          <div className="text-black bg-white rounded-lg  m-2">
            <div
              className="m-auto bg-cover bg-center border-none rounded-lg  flex items-end border"
              style={{
                width: 300,
                height: 400,
                backgroundImage: `url('images/sUCIPTO-1.jpg')`,
              }}
            ></div>
            <div className=" p-4 w-full font-semibold">Dr. Sucipto, M.Si</div>
          </div>
          <div className="text-black bg-white rounded-lg  m-2">
            <div
              className="m-auto bg-cover bg-center border-none rounded-lg  flex items-end border"
              style={{
                width: 300,
                height: 400,
                backgroundImage: `url('images/Kadep-PLS-Dr.-Zulkarnain-M.Pd-M.Si_-scaled.jpg')`,
              }}
            ></div>
            <div className=" p-4 w-full font-semibold">
              Dr. Zulkarnain, M.Pd, M.Si
            </div>
          </div>
        </div>
      </div>

      <script src="landing/js/jquery.min.js"></script>
      <script src="landing/js/jquery.easing.min.js"></script>
      <script src="landing/js/swiper.min.js"></script>
      <script src="landing/js/jquery.magnific-popup.js"></script>
      <script src="landing/js/scripts.js"></script>
    </>
  );
}
