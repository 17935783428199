import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Header(props) {
  const navigate = useNavigate();
  const [isProfileVisible, setProfileVisible] = useState("hidden");
  const [isSideMenuOpen, setSideMenuOpen] = useState("hidden");
  const [thisMenu, setThismenu] = useState(props.menu);
  const [avatar, setAvatar] = useState("");
  const [hadFetchData, setHasFetchedData] = useState(false);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/getself_user`;
    fetch(url, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setAvatar(process.env.REACT_APP_API_BASE_URL + "/" + data.data.avatar);
        setHasFetchedData(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const toggleSideMenu = () => {
    if (isSideMenuOpen == "hidden") {
      setSideMenuOpen("block");
    } else {
      setSideMenuOpen("hidden");
    }
  };
  const toggleProfileMenu = () => {
    setProfileVisible(isProfileVisible == "hidden" ? "block" : "hidden");
  };
  const closeProfileMenu = () => {};
  const handleLogout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/logout`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      navigate("/loginguru");
    } catch (error) {
      console.log(error);
    }
  };
  const ProfileMenu = (props) => {
    return (
      <div>
        <ul
          className={` ${props.display} absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700`}
          aria-label="submenu"
        >
          <li className="flex">
            <Link
              to={`/profil`}
              className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
              href="#"
            >
              <svg
                className="w-4 h-4 mr-3"
                aria-hidden="true"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
              </svg>
              <span>Pengaturan Profil</span>
            </Link>
          </li>
          <li className="flex">
            <a
              onClick={handleLogout}
              className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
              href="#"
            >
              <svg
                className="w-4 h-4 mr-3"
                aria-hidden="true"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
              </svg>
              <span>Keluar</span>
            </a>
          </li>
        </ul>
      </div>
    );
  };
  const ActiveSpan = (props) => {
    if (thisMenu == props.menu) {
      return (
        <span
          className="absolute inset-y-0 left-0 w-1 bg-red-600 rounded-tr-lg rounded-br-lg"
          aria-hidden="true"
        ></span>
      );
    }
  };
  const ActiveClass = (menu) => {
    if (thisMenu == menu) {
      return "inline-flex items-center w-full text-sm font-semibold text-gray-800 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100";
    } else {
      return "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200";
    }
  };
  return (
    <>
      <header
        className="z-10 py-4 bg-white shadow-md dark:bg-gray-800"
        style={{ position: "fixed", top: 0, width: "100%" }}
      >
        <div className="container flex items-center justify-between h-full px-6 mx-auto text-red-600 dark:text-red-300">
          <button
            className="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-red"
            onClick={toggleSideMenu}
            aria-label="Menu"
          >
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <div className="flex flex-1  ">
            <div className="relative w-full max-w-xl mr-6 text-black"></div>
          </div>
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="flex">
              <button className="rounded-md focus:outline-none focus:shadow-outline-red">
                <template x-if="!dark">
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                  </svg>
                </template>
                <template x-if="dark">
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </template>
              </button>
            </li>

            <li className="relative">
              <button
                className="align-middle rounded-full focus:shadow-outline-red focus:outline-none"
                onClick={toggleProfileMenu}
                aria-label="Account"
                aria-haspopup="true"
              >
                <img
                  className="object-cover w-8 h-8 rounded-full"
                  src={avatar}
                  aria-hidden="true"
                />{" "}
              </button>
              <ProfileMenu display={isProfileVisible} />
            </li>
          </ul>
        </div>
      </header>
      <div className={`flex h-screen bg-gray-50 dark:bg-gray-900`}>
        <aside className="z-20 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 md:block flex-shrink-0">
          <div className="py-4 text-gray-500 dark:text-gray-400">
            <Link
              className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200 flex"
              href="#"
            >
              <div className="w-2/12">
                <img src="/logo.png" />
              </div>
              <div className="10/12 pl-2">KUBIMA</div>
            </Link>
            <ul className="mt-6">
              <li className="relative px-6 py-3">
                <ActiveSpan menu="Dashboard" />
                <Link className={ActiveClass("Dashboard")} to={`/dashboard`}>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                  </svg>
                  <span className="ml-4">Dashboard</span>
                </Link>
              </li>
            </ul>
            <ul>
              <li className="relative px-6 py-3">
                <ActiveSpan menu="Kursus" />

                <Link className={ActiveClass("Kursus")} to={`/kursus`}>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
                  </svg>
                  <span className="ml-4">Kursus</span>
                </Link>
              </li>
              <li className="relative px-6 py-3">
                <ActiveSpan menu="Potensi" />

                <Link to={`/potensi`} className={ActiveClass("Potensi")}>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
                  </svg>
                  <span className="ml-4">Potensi</span>
                </Link>
              </li>
              <li className="relative px-6 py-3">
                <ActiveSpan menu="Pengaturan Profil" />

                <Link
                  to={`/profil`}
                  className={ActiveClass("Pengaturan Profil")}
                >
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path>
                    <path d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
                  </svg>
                  <span className="ml-4">Pengaturan Profil</span>
                </Link>
              </li>
            </ul>
          </div>
        </aside>
        <div className="fixed z-10 flex items-end  sm:items-center sm:justify-center"></div>
        <aside
          className={` ${isSideMenuOpen} fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 `}
        >
          <div className="py-4 text-gray-500 dark:text-gray-400">
            <Link
              className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
              href="#"
            >
              Pelatihan
            </Link>
            <ul className="mt-6">
              <li className="relative px-6 py-3">
                <ActiveSpan menu="Dashboard" />
                <Link className={ActiveClass("Dashboard")} to={`/dashboard`}>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                  </svg>
                  <span className="ml-4">Dashboard</span>
                </Link>
              </li>
            </ul>
            <ul>
              <li className="relative px-6 py-3">
                <ActiveSpan menu="Kursus" />

                <Link className={ActiveClass("Kursus")} to={`/kursus`}>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
                  </svg>
                  <span className="ml-4">Kursus</span>
                </Link>
              </li>
              <li className="relative px-6 py-3">
                <ActiveSpan menu="Potensi" />

                <Link to={`/potensi`} className={ActiveClass("Potensi")}>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
                  </svg>
                  <span className="ml-4">Potensi</span>
                </Link>
              </li>
              <li className="relative px-6 py-3">
                <ActiveSpan menu="Pengaturan Profil" />

                <Link
                  to={`/profil`}
                  className={ActiveClass("Pengaturan Profil")}
                >
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path>
                    <path d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
                  </svg>
                  <span className="ml-4">Pengaturan Profil</span>
                </Link>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </>
  );
}
