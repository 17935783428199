import { useState, CSSProperties } from "react";
import BeatLoader from "react-spinners/BeatLoader";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function Loader() {
  let color = "#ef4444";

  return (
    <div className="sweet-loading">
      <BeatLoader
        color={color}
        loading={true}
        cssOverride={override}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default Loader;
