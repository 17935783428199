import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WebToast from "../components/WebToast";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { Modal } from "react-overlays";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import Loader from "../components/Loader";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Potensi() {
  const navigate = useNavigate();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [id, setID] = useState("");
  const [title, setTitle] = useState("");
  const [file, setFile] = useState("");
  const [data, setData] = useState(null);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleClose = () => setModalAdd(false);
  const handleEditClose = () => setModalEdit(false);
  const handleDeleteClose = () => setModalDelete(false);
  const renderBackdrop = (props) => <div className="backdrop" {...props} />;

  const handlePost = async (event) => {
    try {
      setData(null);
      event.preventDefault();
      const form = event.target;
      const formData = new FormData(form);
      // get value
      const contentState = editorState.getCurrentContent();
      const desc = stateToHTML(contentState);
      formData.append("desc", desc);

      if (file) {
        formData.append("file", file);
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/potensi/add_potensi`,
        {
          method: "POST",
          body: formData,
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        WebToast("success", data.message);
        setModalAdd(false);
      } else {
        WebToast("error", data.message);
      }
      loadTable();
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };

  const handlePut = async (event) => {
    try {
      setData(null);
      event.preventDefault();
      const form = event.target;
      const formData = new FormData(form);
      // get value
      const contentState = editorState.getCurrentContent();
      const desc = stateToHTML(contentState);
      formData.append("desc", desc);

      if (file) {
        formData.append("file", file);
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/potensi/edit_potensi/${id}`,
        {
          method: "PUT",
          body: formData,
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        WebToast("success", data.message);
        setModalEdit(false);
      } else {
        WebToast("error", data.message);
      }
      loadTable();
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const handleDelete = async (event) => {
    try {
      setData(null);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/potensi/delete_potensi/${id}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
      } else if (response.ok) {
        WebToast("success", data.message);
        setModalDelete(false);
      } else {
        WebToast("error", data.message);
      }
      loadTable();
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };
  const loadTable = async () => {
    try {
      setData(null);
      const url = `${process.env.REACT_APP_API_BASE_URL}/potensi/get_potensi_all`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setData(data.data);
      if (response.status == 401) {
        WebToast("error", "Session habis, silahkan login ulang");
        navigate("/loginguru");
        return false;
      }
    } catch (error) {
      console.log(error);
      WebToast("error", "Sistem Error");
    }
  };

  const TableData = (props) => {
    if (data) {
      if (data.length > 0) {
        return (
          <>
            {data.map((item, index) => (
              <tr className="text-gray-700 dark:text-gray-400" key={index}>
                <td className="px-4 py-3 text-sm">{index + 1}</td>

                <td className="px-4 py-3">
                  <img
                    alt=""
                    style={{ width: "100px" }}
                    src={`${process.env.REACT_APP_API_BASE_URL}/${item.thumbnail}`}
                  ></img>
                </td>
                <td className="px-4 py-3 text-sm">{item.title}</td>
                <td className="px-4 py-3">
                  <div className="flex items-center space-x-4 text-sm">
                    <button
                      onClick={() => {
                        setModalEdit(true);
                        setTitle(item.title);
                        // Convert HTML to ContentState
                        const blocksFromHTML = convertFromHTML(item.desc);
                        const contentState = ContentState.createFromBlockArray(
                          blocksFromHTML.contentBlocks,
                          blocksFromHTML.entityMap
                        );
                        setEditorState(
                          EditorState.createWithContent(contentState)
                        );
                        setID(item.id);
                      }}
                      className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-black-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray hover:text-red-500"
                      aria-label="Edit"
                    >
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        setID(item.id);
                        setModalDelete(true);
                      }}
                      className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-black-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray hover:text-red-500"
                      aria-label="View"
                    >
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </>
        );
      } else {
        return (
          <tr>
            <td colSpan={10} className="text-center">
              Tidak ada data
            </td>
          </tr>
        );
      }
    } else {
      return (
        <tr>
          <td colSpan={10}>
            <Loader />
          </td>
        </tr>
      );
    }
  };
  useEffect(() => {
    loadTable();
  }, []);

  return (
    <div className={`flex h-screen bg-gray-50 dark:bg-gray-900 `}>
      <Header menu="Potensi" />
      <div className="flex flex-col flex-1 w-full mt-16">
        <main className="h-full pb-16 overflow-y-auto">
          <div className="container grid px-6 mx-auto">
            <h2 className="mt-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
              Manajemen Potensi
            </h2>
            <div className="w-full overflow-hidden rounded-lg shadow-xs mt-10">
              <div className="flex items-end justify-between w-full">
                <button
                  type="button"
                  onClick={() => {
                    setModalAdd(true);
                    setTitle("");
                    setEditorState(
                      EditorState.createWithContent(
                        ContentState.createFromText("")
                      )
                    );
                  }}
                  className="flex items-center justify-between px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                  </svg>
                  <span className=" ml-2">Tambah</span>
                </button>

                <input
                  className="hidden w-4/12 mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-red-600 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                  placeholder="Cari..."
                />
              </div>
              <div className="w-full overflow-x-auto mt-5">
                <table className="w-full whitespace-no-wrap">
                  <thead>
                    <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                      <th className="px-4 py-3">No.</th>
                      <th className="px-4 py-3">Thumbnail</th>
                      <th className="px-4 py-3">Foto</th>
                      <th className="px-4 py-3">Opsi</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    <TableData data={data} />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Modal
        className="modal"
        show={modalAdd}
        onHide={handleClose}
        renderBackdrop={renderBackdrop}
      >
        <div
          className=" fixed z-30 top-1.5  w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg h-screen overflow-y-auto"
          role="dialog"
          id="modal"
        >
          <header className="flex justify-end">
            <button
              onClick={() => setModalAdd(false)}
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
              </svg>
            </button>
          </header>
          <form onSubmit={handlePost}>
            <div className="mt-4 mb-6">
              <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
                Tambah Potensi
              </p>
              <label className="block text-sm mt-3">
                <span className="text-gray-700 dark:text-gray-400">
                  Judul Potensi
                </span>
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                />
              </label>
              <label className="block text-sm mt-3">
                <span className="text-gray-700 dark:text-gray-400">
                  Gambar Thumbnail
                </span>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => {
                    const file = e.target.files[0]; // Get the selected file
                    setFile(file);
                  }}
                  accept="image/*"
                  className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                />
              </label>
              <label className="block text-sm mt-3">
                <span className="text-gray-700 dark:text-gray-400">
                  Deskripsi
                </span>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  placeholder="Masukkan deskripsi..."
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                />
              </label>
            </div>
            <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
              <button
                type="button"
                onClick={() => setModalAdd(false)}
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
              >
                Batal
              </button>
              <button
                type="submit"
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
              >
                Simpan
              </button>
            </footer>
          </form>
        </div>
      </Modal>
      <Modal
        className="modal"
        show={modalEdit}
        onHide={handleEditClose}
        renderBackdrop={renderBackdrop}
      >
        <div
          className=" fixed z-30 top-1.5  w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg h-screen overflow-y-auto"
          role="dialog"
          id="modaledit"
        >
          <header className="flex justify-end">
            <button
              onClick={() => setModalEdit(false)}
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
              </svg>
            </button>
          </header>
          <form onSubmit={handlePut}>
            <div className="mt-4 mb-6">
              <p className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
                Edit Potensi
              </p>
              <label className="block text-sm mt-3">
                <span className="text-gray-700 dark:text-gray-400">
                  Judul Potensi
                </span>
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                />
              </label>
              <label className="block text-sm mt-3">
                <span className="text-gray-700 dark:text-gray-400">
                  Gambar Thumbnail
                </span>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => {
                    const file = e.target.files[0]; // Get the selected file
                    setFile(file);
                  }}
                  accept="image/*"
                  className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                />
              </label>
              <label className="block text-sm mt-3">
                <span className="text-gray-700 dark:text-gray-400">
                  Deskripsi
                </span>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  placeholder="Masukkan deskripsi..."
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                />
              </label>
            </div>
            <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
              <button
                type="button"
                onClick={() => setModalEdit(false)}
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
              >
                Batal
              </button>
              <button
                type="submit"
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
              >
                Simpan
              </button>
            </footer>
          </form>
        </div>
      </Modal>
      <Modal
        className="modal"
        show={modalDelete}
        onHide={handleDeleteClose}
        renderBackdrop={renderBackdrop}
      >
        <div
          className=" fixed z-30 top-1.5  px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg right-10 md:w-6/12 sm:w-full"
          role="dialog"
          id="modalhapus"
        >
          <header className="flex justify-end">
            <button
              onClick={() => setModalDelete(false)}
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
              </svg>
            </button>
          </header>
          <div>Anda yakin ingin menghapus data ini?</div>
          <footer className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <button
              type="button"
              onClick={() => setModalDelete(false)}
              className="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
            >
              Batal
            </button>
            <button
              onClick={handleDelete}
              className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple"
            >
              Hapus
            </button>
          </footer>
        </div>
      </Modal>
    </div>
  );
}
