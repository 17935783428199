import { Link, useNavigate } from "react-router-dom";
import Toast from "../components/Toast";
import Nav from "../components/Nav";
import { useState, useEffect } from "react";
import Loader from "../components/Loader";

export default function Explore() {
  const navigate = useNavigate();
  const base_API = process.env.REACT_APP_API_BASE_URL;

  //! COURSE BELUM TERAMBIL
  const [courses, setCourses] = useState(null);
  const loadCourse = async (keyword = "") => {
    try {
      setCourses(null);
      const url = `${process.env.REACT_APP_API_BASE_URL}/users_courses/search_course`;
      const jsonData = {
        keyword: keyword,
      };
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(jsonData),
        credentials: "include",
      });
      const data = await response.json();
      if (response.status == 401) {
        Toast("error", "Session habis, silahkan login ulang");
        navigate("/login");
        return false;
      }
      setCourses(data.data);
    } catch (error) {
      console.log(error);
      Toast("error", "Sistem Error");
    }
  };

  const CardCourse = () => {
    if (courses) {
      if (courses.length > 0) {
        return (
          <>
            {courses.map((item, index) => {
              return (
                <div className="w-6/12 p-2" key={index}>
                  <Link to={`/detailexplore/${item.id}`}>
                    <div className="bg-white rounded-md shadow-md p-4">
                      <div
                        className=" bg-cover rounded-full bg-center m-auto"
                        style={{
                          width: 70,
                          height: 70,
                          backgroundImage: `url('${
                            base_API +
                            "/" +
                            item.logo.replace(/\\/g, "/").replace(/\s+/g, "")
                          }')`,
                        }}
                      ></div>
                      <div className="text-center font-semibold mt-3">
                        {item.title}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </>
        );
      } else {
        return (
          <>
            <div className=" w-full lg:w-7/12 m-auto bg-white p-4">
              <p className=" text-center font-semibold text-base">
                Kursus tidak ditemukan
              </p>
              <img src="/images/Working from anywhere-pana.svg" />
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <Loader />
        </>
      );
    }
  };
  useEffect(() => {
    loadCourse();
  }, []);
  return (
    <main className=" min-h-screen flex-col items-center justify-between bg-gray-100 dark:bg-gray-800">
      <div className=" gradient-red mx-2 pb-8">
        <div className="flex justify-end p-4">
          <img style={{ width: 50 }} src="/logo.png" />
        </div>
        <div className="text-white font-semibold text-center text-xl">
          Jelajahi Kursus
        </div>
        <div className="mt-5 mx-4">
          <input
            className="w-full rounded-full text-lg p-3"
            placeholder="Cari Kursus"
            onChange={(e) => {
              loadCourse(e.target.value);
            }}
            type="text"
          />
        </div>
      </div>
      <div className="mt-5 mx-3 flex flex-wrap">
        <CardCourse />
      </div>
      <Nav menu="explore" />
    </main>
  );
}
